import { AddressPublicType, License, Physician } from 'types/common';
import { FormPhysician, FormLicense } from 'types/form';
import { ESource } from 'types/operation';

export enum EStep {
    PROFILE = 0,
    HEALTHCARE_EXPERIENCE = 1,
    EDUCATION = 2,
    ADDITIONAL_INFORMATION = 3,
    PRACTICE = 4,
    LICENSE = 5,
    SERVICES = 6,
    KLARITY_SERVICES = 7,
    INSURANCE = 8,
    BIOGRAPHIC = 9,
    PREVIEW = 10,
    FINISH = 11,
}

export enum StepValue {
    PROFILE = 'profile',
    SERVICES = 'services',
    KLARITY_SERVICES = 'klarity services',
    PRACTICE = 'practice',
    HEALTHCARE_EXPERIENCE = 'healthcare Experience',
    EDUCATION = 'education',
    ADDITIONAL_INFORMATION = 'recognition',
    INSURANCE = 'insurance',
    BIOGRAPHIC = 'Biographic',
    LICENSE = 'license',
    PREVIEW = 'review',
}

export enum ProfileFormFieldEnum {
    EMAIL = 'email',
    GMAIL = 'gmail',
    GMAIL_PASSWORD = 'gmailPassword',
    TEL = 'tel',
    MAILING_ADDRESS = 'address',
    STATE = 'state',
    NPI_NUMBER = 'npi',
    YEARS_OF_EXPERIENCE = 'yearExp',
    SPECIALITYINHEALTH = 'specialityInHealth',
    SPECIALITYINFAMILY = 'specialityInFamily',
    FIRST_NAME = 'firstName',
    MIDDLE_NAME = 'middleName',
    LAST_NAME = 'lastName',
    LICENSES = 'licenses',
    TITLE = 'title',
    INTRO = 'intro',
    PRIMARY_LANGUAGE = 'primaryLanguage',
    FOREIGN_LANGUAGE = 'foreignLanguage',
    PROFILE_PHOTO = 'photoList',
    ADDITIONAL_PHOTO = 'additionalPhotoList',
    ID = 'id',
    STATUS = 'status',
    LIST_STATUS = 'listStatus',
    ALLOW_AUTH = 'allowAuth',
    AUDIT_STATUS = 'auditStatus',
    UPDATE_STATUS = 'updateStatus',
    PHOTO = 'photo',
    CITY = 'city',
    ZIP_CODE = 'zip',
    WEBSITE = 'website',
    CREDENTIAL = 'credential',
    HEADLINE = 'headLine',
    NPI_NOT_REQUIRED = 'npiNotRequired',
    HAS_EHR_ACCOUNT_PWD = 'hasEhrAccountPwd',
    PRODUCT = 'product',
    CHANNEL_PREFERENCE = 'channelPreference',
    SIGN_UP_FROM = 'signUpFrom',
}

export interface ProfileFormInterface {
    [ProfileFormFieldEnum.ID]: number;
    [ProfileFormFieldEnum.EMAIL]: string;
    [ProfileFormFieldEnum.TEL]: string;
    [ProfileFormFieldEnum.MAILING_ADDRESS]: string;
    [ProfileFormFieldEnum.STATE]: string;
    [ProfileFormFieldEnum.NPI_NUMBER]: string;
    [ProfileFormFieldEnum.YEARS_OF_EXPERIENCE]: string;
    [ProfileFormFieldEnum.SPECIALITYINHEALTH]: string[];
    [ProfileFormFieldEnum.SPECIALITYINFAMILY]: string[];
    [ProfileFormFieldEnum.FIRST_NAME]: string;
    [ProfileFormFieldEnum.LAST_NAME]: string;
    [ProfileFormFieldEnum.LICENSES]: Array<License>;
    [ProfileFormFieldEnum.TITLE]: string;
    [ProfileFormFieldEnum.INTRO]: string;
    [ProfileFormFieldEnum.PRIMARY_LANGUAGE]: string;
    [ProfileFormFieldEnum.FOREIGN_LANGUAGE]: Array<string>;
    [ProfileFormFieldEnum.PROFILE_PHOTO]: Array<string>;
    [ProfileFormFieldEnum.ADDITIONAL_PHOTO]: Array<string>;
    [ProfileFormFieldEnum.AUDIT_STATUS]: string;
    [ProfileFormFieldEnum.PHOTO]: string;
    [ProfileFormFieldEnum.CREDENTIAL]: string;
    [ProfileFormFieldEnum.HEADLINE]: string;
    [ProfileFormFieldEnum.SIGN_UP_FROM]: ESource;
}

export enum ServicesFormFieldEnum {
    IN_PERSON = 'inperson',
    ZIP_CODE = 'personZip',
    ADDRESS = 'personAddress',
    CITY = 'personCity',
    STATE = 'personState',
    DURATION = 'duration',
    INTAKE_FORM = 'intakeForm',
    NAME = 'name',
    PERSON_TYPE = 'personType',
    TELEHEALTH_TYPE = 'telehealthType',
    PATIENT_TYPE_IN_PERSON = 'patientTypeInPerson',
    PATIENT_TYPE_TELEHEALTH = 'patientTypeTelehealth',
    CONSULTATION_SESSION_INTAKE_FORM = 'consultationSessionIntakeForm',
    CONSULTATION_SESSION_DURATION = 'consultationSessionDuration',
    INITIAL_VISIT_DURATION = 'initialVisitDuration',
    INITIAL_VISIT_INTAKE_FORM = 'initialVisitIntakeForm',
    FOLLOW_UP_DURATION = 'followUpDuration',
    FOLLOW_UP_INTAKE_FORM = 'followUpIntakeForm',
    REFILL = 'refill',
    REFILL_DURATION = 'refillDuration',
    REFILL_INTAKE_FORM = 'refillIntakeForm',
    CHECKED = 'checked',
    DESCRIPTION = 'description',
    ID = 'id',
    IN_PERSON_CHECKED = 'inpersonChecked',
    TELEHEALTH_CHECKED = 'telehealthChecked',
    PRICE = 'price',
    COLOR_ID = 'colorId',
    SERVICE_TYPE = 'type',
    SEND_FORM = 'sendForm',
    ADDRESS_ID = 'addressId',
    ENABLE_SLIDING_SCALE = 'enableSlidingScale',
    LOWEST_PRICE = 'lowestPrice',
    HIGHEST_PRICE = 'highestPrice',
}

export interface ServicesFormInterface {
    [ServicesFormFieldEnum.ID]: number;
    [ServicesFormFieldEnum.IN_PERSON]: boolean;
    [ServicesFormFieldEnum.ZIP_CODE]: string;
    [ServicesFormFieldEnum.ADDRESS]: string;
    [ServicesFormFieldEnum.CITY]: string;
    [ServicesFormFieldEnum.STATE]: string;
    [ServicesFormFieldEnum.DURATION]: string;
    [ServicesFormFieldEnum.INTAKE_FORM]: string;
    [ServicesFormFieldEnum.NAME]: string;
    [ServicesFormFieldEnum.PERSON_TYPE]: Array<string>;
    [ServicesFormFieldEnum.TELEHEALTH_TYPE]: Array<string>;
    [ServicesFormFieldEnum.PATIENT_TYPE_IN_PERSON]: Array<string>;
    [ServicesFormFieldEnum.PATIENT_TYPE_TELEHEALTH]: Array<string>;
    [ServicesFormFieldEnum.CONSULTATION_SESSION_INTAKE_FORM]: boolean;
    [ServicesFormFieldEnum.CONSULTATION_SESSION_DURATION]: number;
    [ServicesFormFieldEnum.INITIAL_VISIT_DURATION]: number;
    [ServicesFormFieldEnum.INITIAL_VISIT_INTAKE_FORM]: string;
    [ServicesFormFieldEnum.FOLLOW_UP_DURATION]: number;
    [ServicesFormFieldEnum.FOLLOW_UP_INTAKE_FORM]: string;
    [ServicesFormFieldEnum.REFILL]: boolean;
    [ServicesFormFieldEnum.REFILL_DURATION]: number;
    [ServicesFormFieldEnum.REFILL_INTAKE_FORM]: string;
    [ServicesFormFieldEnum.CHECKED]: boolean;
    [ServicesFormFieldEnum.DESCRIPTION]: string;
    [ServicesFormFieldEnum.IN_PERSON_CHECKED]?: boolean;
    [ServicesFormFieldEnum.TELEHEALTH_CHECKED]: boolean;
    [ServicesFormFieldEnum.PRICE]: string;
    [ServicesFormFieldEnum.SERVICE_TYPE]: number;
    [ServicesFormFieldEnum.SEND_FORM]: string;
    [ServicesFormFieldEnum.ADDRESS_ID]: number;
    [ServicesFormFieldEnum.ENABLE_SLIDING_SCALE]: boolean;
    [ServicesFormFieldEnum.LOWEST_PRICE]?: number;
    [ServicesFormFieldEnum.HIGHEST_PRICE]?: number;
}

export enum PracticeFormFieldEnum {
    SPECIALITY_IN_FAMILY = 'specialityInFamily',
    SPECIALITY_IN_HEALTH = 'specialityInHealth',
    TREATMENT_PHILOSOPHY = 'treatmentPhilosophy',
    TREATMENT_APPROACH = 'treatmentApproach',
    ADDITIONAL_PRACTICE = 'additionalPractice',
    ADDRESSLIST = 'addressList',
    SPECIALITY_HIGHLIGHT_LIST = 'specialtyHighlightList',
}

export enum AdditionalPracticeFormFieldEnum {
    NAME = 'practiceName',
    ADDRESS = 'practiceAddress',
    CITY = 'practiceCity',
    STATE = 'practiceState',
    ZIP_CODE = 'practiceZip',
    EMAIL = 'practiceEmail',
    TEL = 'practicePhone',
    WEBSITE = 'practiceWebsite',
    COMPANY_TYPE = 'companyType',
    DESCRIPTION = 'practiceDesc',
    LOGO = 'practiceLogo',
    PRACTICE_FRONT_URL = 'practiceFrontUrl',
    KIWI_HEALTH_WEBSITE = 'kiwihealthWebsite',
    SPECIALTY_LIST = 'specialtyList',
    CONDITION_TREATED_LIST = 'conditionTreatedList',
    IS_DEFAULT = 'isDefault',
    AS_MAILING = 'asMailing',
    NOT_PUBLISH = 'notPublish',
    ID = 'id',
    NOT_HAVE_NAME = 'notHaveName',
    NOT_HAVE_PHONE = 'notHavePhone',
    ADDRESS_PUBLIC_TYPE = 'publicType',
    TAX_ID_TYPE = 'taxIdType',
    TAX_ID = 'taxId',
    SETUPLAPOPTION = 'setUpLapOption',
    W9FORM = 'w9Form',
    VFD_PHONE_LINE = 'vfdPhoneLine',
    APPOINTMENT_INTEGRATION_URL = 'appointmentIntegrationUrl',
    AVA_AUTO_REPLY = 'avaAutoReply',
}

export interface AdditionalPracticeForm {
    [AdditionalPracticeFormFieldEnum.NAME]: string;
    [AdditionalPracticeFormFieldEnum.ADDRESS]: string;
    [AdditionalPracticeFormFieldEnum.CITY]: string;
    [AdditionalPracticeFormFieldEnum.STATE]: string;
    [AdditionalPracticeFormFieldEnum.ZIP_CODE]: string;
    [AdditionalPracticeFormFieldEnum.EMAIL]: string;
    [AdditionalPracticeFormFieldEnum.TEL]: string;
    [AdditionalPracticeFormFieldEnum.WEBSITE]: string;
    [AdditionalPracticeFormFieldEnum.COMPANY_TYPE]: string;
    [AdditionalPracticeFormFieldEnum.DESCRIPTION]: string;
    [AdditionalPracticeFormFieldEnum.LOGO]: string;
    [AdditionalPracticeFormFieldEnum.NOT_HAVE_NAME]: boolean;
    [AdditionalPracticeFormFieldEnum.ADDRESS_PUBLIC_TYPE]: string;
}

export interface PracitceAddressForm {
    [AdditionalPracticeFormFieldEnum.ID]?: number;
    [AdditionalPracticeFormFieldEnum.IS_DEFAULT]: boolean;
    [AdditionalPracticeFormFieldEnum.AS_MAILING]: boolean;
    [AdditionalPracticeFormFieldEnum.NOT_PUBLISH]: boolean;
    [AdditionalPracticeFormFieldEnum.ADDRESS]: string;
    [AdditionalPracticeFormFieldEnum.CITY]: string;
    [AdditionalPracticeFormFieldEnum.STATE]: string;
    [AdditionalPracticeFormFieldEnum.ZIP_CODE]: string;
    [AdditionalPracticeFormFieldEnum.ADDRESS_PUBLIC_TYPE]: AddressPublicType;
}

export interface PracticeFormInterface {
    [PracticeFormFieldEnum.SPECIALITY_IN_FAMILY]: string;
    [PracticeFormFieldEnum.SPECIALITY_IN_HEALTH]: string;
    [PracticeFormFieldEnum.TREATMENT_PHILOSOPHY]: string;
    [PracticeFormFieldEnum.TREATMENT_APPROACH]: string;
    [AdditionalPracticeFormFieldEnum.NAME]: string;
    [AdditionalPracticeFormFieldEnum.ADDRESS]: string;
    [AdditionalPracticeFormFieldEnum.CITY]: string;
    [AdditionalPracticeFormFieldEnum.STATE]: string;
    [AdditionalPracticeFormFieldEnum.ZIP_CODE]: string;
    [AdditionalPracticeFormFieldEnum.EMAIL]: string;
    [AdditionalPracticeFormFieldEnum.TEL]: string;
    [AdditionalPracticeFormFieldEnum.WEBSITE]: string;
    [AdditionalPracticeFormFieldEnum.COMPANY_TYPE]: string;
    [AdditionalPracticeFormFieldEnum.DESCRIPTION]: string;
    [AdditionalPracticeFormFieldEnum.LOGO]: string;
    [AdditionalPracticeFormFieldEnum.PRACTICE_FRONT_URL]: string;
    [AdditionalPracticeFormFieldEnum.KIWI_HEALTH_WEBSITE]: string;
    [AdditionalPracticeFormFieldEnum.SPECIALTY_LIST]: string[];
    [AdditionalPracticeFormFieldEnum.CONDITION_TREATED_LIST]: string[];
    [AdditionalPracticeFormFieldEnum.NOT_HAVE_NAME]: boolean;
    [AdditionalPracticeFormFieldEnum.NOT_HAVE_PHONE]: boolean;
    [AdditionalPracticeFormFieldEnum.ADDRESS_PUBLIC_TYPE]: AddressPublicType;
    [PracticeFormFieldEnum.ADDRESSLIST]: Array<PracitceAddressForm>;
    [AdditionalPracticeFormFieldEnum.TAX_ID_TYPE]: string;
    [AdditionalPracticeFormFieldEnum.TAX_ID]: string;
    [AdditionalPracticeFormFieldEnum.VFD_PHONE_LINE]?: string;
}

export enum HealthcareExperienceFieldEnum {
    ORGANIZATION_NAME = 'organizationName',
    TITLE = 'title',
    IS_CURRENTLY_WORK = 'isCurrentlyWork',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    EMPLOYMENT_TYPE = 'employmentType',
    HOSPITAL_LOCATION = 'hospitalLocation ',
    DESCRIPTION = 'description',
    LOGO = 'logo',
}

export enum HealthcareExperienceFormFieldEnum {
    EXPERIENCE_DATA = 'exprienceData',
}

export interface HealthcareExperienceInterface {
    [HealthcareExperienceFieldEnum.ORGANIZATION_NAME]: string,
    [HealthcareExperienceFieldEnum.TITLE]: string,
    [HealthcareExperienceFieldEnum.IS_CURRENTLY_WORK]: boolean,
    [HealthcareExperienceFieldEnum.START_DATE]: string,
    [HealthcareExperienceFieldEnum.END_DATE]: string,
    [HealthcareExperienceFieldEnum.EMPLOYMENT_TYPE]: string,
    [HealthcareExperienceFieldEnum.HOSPITAL_LOCATION]: string,
    [HealthcareExperienceFieldEnum.DESCRIPTION]: string,
    [HealthcareExperienceFieldEnum.LOGO]: string,
}

export interface HealthcareExperienceFormInterface {
    [HealthcareExperienceFormFieldEnum.EXPERIENCE_DATA]: Array<HealthcareExperienceInterface>
}

export enum EducationFieldEnum {
    SCHOOL_NAME = 'schoolName',
    MAJOR = 'major',
    DEGREE = 'degree',
    SPECIALTIES = 'specialties',
    CERTIFICATES = 'certificates',
    IS_PURSUING_DEGREE = 'isPursuingDegree',
    START_DATE = 'startDate',
    END_DATE = 'endDate ',
    LOGO = 'logo',

}

export enum EducationFormFieldEnum {
    EDUCATION_DATA = 'educationData',
}

export interface EducationInterface {
    [EducationFieldEnum.SCHOOL_NAME]: string,
    [EducationFieldEnum.MAJOR]: string,
    [EducationFieldEnum.IS_PURSUING_DEGREE]: boolean,
    [EducationFieldEnum.DEGREE]: string,
    [EducationFieldEnum.SPECIALTIES]: string,
    [EducationFieldEnum.CERTIFICATES]: string,
    [EducationFieldEnum.START_DATE]: string,
    [EducationFieldEnum.END_DATE]: string,
    [EducationFieldEnum.LOGO]: string,
}

export interface EducationFormInterface {
    [EducationFormFieldEnum.EDUCATION_DATA]: Array<EducationInterface>
}

export enum PayoutPaymentFiledEnum {
    PAYOUT_METHOD = 'payoutMethod',
    PAYMENT_METHOD = 'paymentMethod',
}

export enum ReviewRatingFieldEnum {
    IS_DISPLAY = 'isDisplay',
}

export enum MembershipAssociationFieldEnum {
    ASSOCIATION = 'association',
}

export enum AwardAchievementFieldEnum {
    AWARD_ACHIEVEMENT = 'awardAchievement',
}

export enum PublicationAdditionalResourceFieldEnum {
    PUBLICATION_NAME = 'publicationName',
    AUTHORS = 'authors',
    WEBSITE = 'website',
}

export enum MembershipAssociationListFieldEnum {
    ASSOCIATION_DATA = 'associationData',
}

export enum AwardAchievementListFieldEnum {
    AWARD_ACHIEVEMENT_DATA = 'awardAchievementData',
}

export enum PublicationAdditionalResourceListFieldEnum {
    PUBLICATION_ADDITIONAL_RESOURCE_DATA = 'publicationAdditionalResourceData',
}

export interface MembershipAssociationInterface {
    [MembershipAssociationFieldEnum.ASSOCIATION]: string,
}

export interface AwardAchievementInterface {
    [AwardAchievementFieldEnum.AWARD_ACHIEVEMENT]: string,
}

export interface PublicationAdditionalResourceInterface {
    [PublicationAdditionalResourceFieldEnum.PUBLICATION_NAME]: string;
    [PublicationAdditionalResourceFieldEnum.AUTHORS]: string;
    [PublicationAdditionalResourceFieldEnum.WEBSITE]: string;
}

export interface AdditionalInformationFormInterface {
    [PayoutPaymentFiledEnum.PAYOUT_METHOD]: string;
    [PayoutPaymentFiledEnum.PAYMENT_METHOD]: string;
    [ReviewRatingFieldEnum.IS_DISPLAY]: boolean;
    [MembershipAssociationListFieldEnum.ASSOCIATION_DATA]: Array<MembershipAssociationInterface>;
    [AwardAchievementListFieldEnum.AWARD_ACHIEVEMENT_DATA]: Array<AwardAchievementInterface>;
    [PublicationAdditionalResourceListFieldEnum.PUBLICATION_ADDITIONAL_RESOURCE_DATA]: Array<PublicationAdditionalResourceInterface>
}

export enum QualificationFormFieldEnum {
    STATE = 'state',
    DEA_LICENSES = 'deaLicense',
    NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES = 'nurseAndPhysicianAssistantLicenses',
    CONTROLLED_SUBSTANCE_CERTIFICATION = 'controlledSubstanceCertification',
    CONTINUING_MEDICAL_EDUCATION_CERTIFICATES = 'continuingMedicalEducationCertificates',
    COLLABORATING_PHYSICIAN = 'collaboratingPhysician',
}

export type NurseAndPaFormLicenses = {
    nurse?: Partial<FormLicense>,
    pa?: Partial<FormLicense>
};

export type NurseAndPaLicenses = {
    nurse?: Partial<License>,
    pa?: Partial<License>
};

export type FormMutipleLicense = {
    licenses?: Partial<FormLicense>[]
};

export type MutipleLicense = {
    licenses?: Partial<License>[]
};

export interface QualificationFormFieldEnumInterface {
    [QualificationFormFieldEnum.STATE]: string;
    [QualificationFormFieldEnum.DEA_LICENSES]: FormLicense;
    [QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]: NurseAndPaFormLicenses;
    [QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION]: FormLicense;
    [QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]: FormMutipleLicense;
    [QualificationFormFieldEnum.COLLABORATING_PHYSICIAN]: FormPhysician;
}

export interface QualificationFieldEnumInterface {
    [QualificationFormFieldEnum.STATE]: string;
    [QualificationFormFieldEnum.DEA_LICENSES]: License;
    [QualificationFormFieldEnum.NURSE_AND_PHYSICIAN_ASSISTANT_LICENSES]: NurseAndPaLicenses;
    [QualificationFormFieldEnum.CONTROLLED_SUBSTANCE_CERTIFICATION]: License;
    [QualificationFormFieldEnum.CONTINUING_MEDICAL_EDUCATION_CERTIFICATES]: MutipleLicense;
    [QualificationFormFieldEnum.COLLABORATING_PHYSICIAN]: Physician;
}
export interface FormMutipleQualificationFormFieldEnumInterface {
    qualifications: Partial<QualificationFormFieldEnumInterface>[]
}

export interface PreviewFormInterface extends ProfileFormInterface, FormMutipleQualificationFormFieldEnumInterface {

}
